import React, { Component } from 'react';
import { connect } from 'react-redux';
import './holiday_grid.css';
import { loadNewPage, loadExactPage, filterByValue, loadData, sortBySOLRating, sortByBudget, sortByAlphabet, sortByTravelTime, filterByMonth } from "../../store";
import HolidayCard from "../../components/HolidayCard";
import Modal from "../../components/Modal";
import { setHotelAsFavorite, changeModalState } from "../../store";
import ModalBookTrip from "../ModalBookTrip";
import 'bulma-responsive-tables/css/main.min.css'
const zeroPad = (num, places) => String(num).padStart(places, '0')


class HolidaysGrid extends Component {
  nextPage() {
    this.props.dispatch(loadNewPage({ page: 1 }))
  }

  previousPage() {
    this.props.dispatch(loadNewPage({ page: -1 }));
  }

  goToPage(page) {
    this.props.dispatch(loadExactPage({ page }))
  }
  componentDidMount() {
    this.props.dispatch(loadData({}));
  }
  setFavorite(hotel_name) {
    this.props.dispatch(setHotelAsFavorite({ hotel_name }));
  }

  render() {
    let self = this;

    let holidays = this.props.state.filteredHolidays;
    let destinations = this.props.state.destinations;
    let month_selected = this.props.state.month_selected;

    let options = (this.props.options) ? this.props.options : { "show_selected_only": false, "simplified_view": false };
    let show_selected_only = options.show_selected_only;

    if (show_selected_only) holidays = holidays.filter(a => a.is_favorite == true)

    function HolGrid() {
      if (options.simplified_view) {
        return (
          <section class="section pt-0">
            <div class="container">
              <div class="b-table">
                <div class="table-wrapper has-mobile-cards">
                  <table class="table is-fullwidth is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <th>Hotel Name</th>
                        <th>Destination</th>
                        <th>Official Rating</th>
                        <th>Budget Rating</th>
                        <th>Our Rating</th>
                        <th>Travel Time</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        holidays && holidays.length && holidays.map((holiday, idx) => {
                          return (
                            <tr class="has-text-left">
                              <td data-label="Hotel Name"><a href={holiday.booking_url + "?aid=8074306"} target="_blank"><i class="fas fa-link"></i> {holiday.name}</a></td>
                              <td data-label="Destination">{holiday.destination}</td>
                              <td data-label="Official Rating">{holiday.official_rating}</td>
                              <td data-label="Budget Rating">{holiday.budget_with_flight}</td>
                              <td data-label="Our Rating">{holiday.sol_rating}</td>
                              <td data-label="Travel Time">{zeroPad(parseInt(new Date(parseInt(holiday.travel_time) * 60 * 1000).toISOString().substr(11, 2)), 2) + "h" + zeroPad(parseInt(new Date(parseInt(holiday.travel_time) * 60 * 1000).toISOString().substr(14, 2)), 2) + "mn"}</td>
                              <td class="is-actions-cell">
                                <div class="buttons">
                                  {/*<button class="button is-small is-primary" type="button">
                                    <span class="icon"><i class="fas fa-bars"></i></span>
                          </button>*/}
                                  <button class="button is-small is-danger jb-modal" type="button" onClick={e => {
                                    self.setFavorite(holiday.name);
                                  }}>
                                    <span class="icon"><i class="fas fa-heart"></i></span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        )

      } else {
        return (
          <>
            <section className='section' >
              {/* 
            {

              holidays && holidays.length && holidays.map(holiday => {
                let modal_sub_name = "modal_sub_" + holiday.name.replace(/,/g, '').replace(/ /g, '').replace(/-/g, '');

                return (
                  <ModalBookTrip anchor={modal_sub_name} skyscanner_link={"https://skyscanner.net"} name={holiday.name} booking_link={holiday.booking_url + "?aid=8074306"} />
                )
              })
            }    
          {
            destinations && Object.keys(destinations).map(destination => {
              let modal_dest_name = "modal_dest_" + destination.replace(/,/g, '').replace(/ /g, '').replace(/-/g, '');
              return (
                <Modal anchor={modal_dest_name}
                  name={destination}
                  type="destination" />
              )
            })
          }
          {

            holidays && holidays.length && holidays.map(holiday => {
              let modal_hotel_name = "modal_hol_" + holiday.name.replace(/,/g, '').replace(/ /g, '').replace(/-/g, '');
              return (
                <Modal anchor={modal_hotel_name}
                  name={holiday.name}
                  type="holiday" />
              )
            })
          }
        */}
              {
                holidays && holidays.length && holidays.map((holiday, idx) => {
                  if (idx % 2 === 0 && idx < holidays.length - 1) {
                    const holidays_odd = holidays[idx + 1]
                    return (
                      <div className='columns is-multiline'>
                        <div className="column">
                          <HolidayCard
                            holiday={holiday}
                            destination={destinations[holiday.destination]}
                            month_seleted={month_selected}
                          />
                        </div>
                        <div className="column">
                          <HolidayCard
                            holiday={holidays_odd}
                            destination={destinations[holiday.destination]}
                            month_seleted={month_selected}
                          />
                        </div>
                      </div>
                    )
                  } else if (idx % 2 === 0) {
                    return (
                      <div className='columns is-multiline'>
                        <div className="column">
                          <HolidayCard
                            holiday={holiday}
                            destination={destinations[holiday.destination]}
                            month_seleted={month_selected}
                          />
                        </div>
                        <div className="column">
                          <div className='container'>
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div></div>
                    )
                  }
                })
              }
            </section>
            <section className='section'>
              <div className='container'>
                <nav className="pagination" role="navigation" aria-label="pagination">
                  <button className="button pagination-previous" onClick={() => {
                    self.previousPage()
                  }}>Previous
                  </button>
                  <button className="button pagination-next" onClick={() => {
                    self.nextPage()
                  }}>Next page
                  </button>
                  <ul className="pagination-list">
                    {
                      [...Array(self.props.state.filteredPages)].map((value, index) => (
                        <button
                          className={`button pagination-link ${self.props.state.currentPage === index + 1 ? "is-current" : ""}`}
                          aria-label="Page 1"
                          onClick={() => self.goToPage(index + 1)}
                          aria-current="page">
                          {index + 1}
                        </button>
                      ))
                    }
                  </ul>
                </nav>

              </div>
            </section>
          </>
        )
      }
    }
    /*
        const queryParameters = new URLSearchParams(window.location.search)
        const hotel_name = queryParameters.get("hotel_name")
        
        if(hotel_name && holidays && holidays.length > 1)
          this.props.dispatch(filterByValue({ value: hotel_name }))
    */


    return (
      <>
      <HolGrid />
      </>
    )
  }
}

function mapStateToProps(state) {
  return { state };
}

export default connect(mapStateToProps)(HolidaysGrid);