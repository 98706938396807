import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Navbar from './components/Navbar'
import SocialMedias from './components/SocialMedias'
import MailingList from './components/MailingList'
import Footer from './components/Footer'
import MainTabs from './components/MainTabs'
import RegisterSubscribers from './components/RegisterSubscribers'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FAQs from './components/FAQs';


const faqs = require('.//data/faqs.json')

const TRACKING_ID_GA = "UA-XXXXX-X";

// ReactGA.initialize(TRACKING_ID_GA);
// Can track event and outbond links 
// https://github.com/react-ga/react-ga
// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/

function NoMatch() {
  return (
    <h1><strong>Something is wrong, what were you looking for?</strong></h1>
  );
}
function About() {
  return (
    <h1><strong>About Sol Passport</strong></h1>
  );
}

function Help() {
  return (
    <div className='main-content pt-5'>
      <div className='container pt-5'>
        <div className="columns is-multiline">
          <div class="column is-8">
            <div class="box">
              <div class="box-header">
                <h2 class="title is-4 mt-0">
                  Sol Passport Help
                </h2>
                <small>Get Help on Using Sol Passport</small>
              </div>
              <div class="box-content ">
                <table class="table mx-auto is-striped is-fullwidth">
                  <thead className='is-size-5'>
                    <tr>
                      <th>Table of content</th>
                    </tr></thead>
                  <tbody className='is-size-6 has-text-weight-normal'>
                    <tr>
                      <th><a href="#about_sol_passport" title="About Sol Passport">About Sol Passport</a></th>
                    </tr>
                    <tr>
                      <th><a href="#what_is_sol_passport" title="What is Sol Passport"> - What is Sol Passport</a></th>
                    </tr>
                    <tr>
                      <th><a href="#about_our_hotels" title="About Our Hotels">About Our Hotels</a></th>
                    </tr>
                    <tr>
                      <th><a href="#how_select_hotels" title="How We select Hotels"> - How we select our Hotels</a></th>
                    </tr>
                    <tr>
                      <th><a href="#about_sol_rating" title="About Sol Rating"> - How the SOL Rating is defined</a></th>
                    </tr>
                    <tr>
                      <th><a href="#about_budget_rating" title="About Budget Rating"> - How the budget rating is defined</a></th>
                    </tr>
                    <tr>
                      <th><a href="#about_our_destinations" title="About Our Destinations">About our Destinations</a></th>
                    </tr>
                    <tr>
                      <th><a href="#how_define_family_friendly" title="How we define family friendliness"> - How we define a destination as family friendly</a></th>
                    </tr>
                    <tr>
                      <th><a href="#sun_friendly_months" title="Sun Friendly Months definition"> - How we define the suitable months for sun</a></th>
                    </tr>
                  </tbody>
                </table>
                <div class="box-content-text">
                  <h3><a id='about_sol_passport'>About Sol Passport</a></h3>
                  <h4><a id='what_is_sol_passport'>What is Sol Passport</a></h4>
                  <p class="content has-text-justified has-text-left">Sol Passport has been created in order to help find easily the perfect getaway to the sun at any time of the year.
                    <br />We realized that despite all the travel websites available, it is actually very difficult to find:

                    <dl className='pt-5 pb-5'>
                      <dt><b>A Destination</b></dt>
                      <dd>Where to go to make sure to have some sun at any time of the year?
                      </dd>
                      <dt><b>An Hotel</b></dt><dd>Where to stay in the destination previously selected? What are the best family friendly hotels? The best Adults resorts? How expensive?
                      </dd>
                      <dt><b>A Good Deal</b></dt><dd>And finally, it is extremely difficult to find the best deals for the destinations/hotels selected
                      </dd>
                    </dl>
                    All this process used to take days if not weeks... But, it was before Sol Passport!
                    Indeed, we did all the hard work and revealed it in the simplest way on our website!
                  </p>
                  <p class="has-text-justified has-text-left">
                    On Sol Passport, simply select the month of departure and you will be offered a list of accommodations around the globe that guarantee you to have a sunny weather and a great experience for everybody in your family.
                    Each package is rated based on its quality and family friendliness for. You can also sort the selection by travel time in order to find the packages the closest to your home. You can sort by budget or even rating!
                    Finding the best family friendly resorts has never been so easy.
                  </p>
                  <h4><a id='about_our_hotels'>About our Hotels</a></h4>
                  <h4><a id='how_select_hotels'>How we select our Hotels</a></h4>
                  <p class="has-text-justified has-text-left">All the hotels we select are catering for families needs.
                    The official rating must be 3, 4 or 5 stars and they must have good ratings
                    on the main hotels websites.</p>
                  <p class="has-text-justified has-text-left">Once selected, we gather all the information we can about them.
                    We look mostly for family friendly facilities like kids club, splash park, kids buffet, baby disco, sports etc..
                  </p>
                  <h4><a id='about_sol_rating'>How the SOL Rating is defined</a></h4>
                  <p class="has-text-justified has-text-left">The SOL (Sol Passport) rating is made of 3 parts.</p>
                  <p class="has-text-justified has-text-left">The first part of the rating is made of an average of the notes on the main hotels website.
                    This average accounts for 3 points.
                  </p>
                  <p class="has-text-justified has-text-left">Then, we check all the facilities, activities linked to families.
                    Depending on how kids friendly the hotel is, we grant a notation on 1.5 points (0.5 is a bit family friendly while 1.5 is the top)
                  </p>
                  <p class="has-text-justified has-text-left">
                    Then, the final 0.5 points are linked to the family "friendliness" of the destination.
                    If the destination contains zoos, amusements parks, water parks, museums etc... Then, we rate 0.5.
                    If there is a bit of that but not much, we give 0.25. And we give 0 if there is almost nothing of that
                  </p>
                  <h4><a id='about_budget_rating'>How the budget rating is defined</a></h4>
                  <p class="has-text-justified has-text-left">
                    The Budget rating is done using 2 elements: the price of the hotel and the price of the flight
                  </p>
                  <p class="has-text-justified has-text-left">
                    We simulate the hotel prices for 1 week in summer holidays (or winter holidays for the caribeans for example) for a family of 4 with 2 children aged more than 4
                  </p>
                  <p class="has-text-justified has-text-left">
                    Then, we get the price of the return flights for the destination. Standard flight with checked in luggages
                  </p>
                  <p class="has-text-justified has-text-left">
                    We add the 2 prices and then we apply the following rule
                    <ul>
                      <li>- If the price is less than £1,800 , we give a single $</li>
                      <li>- If the price is less than £2,800, we give $$</li>
                      <li>- If the price is less than £4,300, we give $$$</li>
                      <li>- If the price is less than £6,300, we give $$$$</li>
                      <li>- If the price is less than £8,800, we give $$$$$</li>
                      <li>- If the price is more than £8,800, we give $$$$$+</li>
                    </ul>
                  </p>
                  <h4><a id='about_our_destinations'>About our Destinations</a></h4>
                  <h4><a id='how_define_family_friendly'>How we define a destination as family friendly</a></h4>
                  <p class="has-text-justified has-text-left">As mentioned previously, we define a destination as family friendly if it
                    is home of plenty of activities like zoos, water parks, amusement parks, museums etc...
                  </p>
                  <p class="has-text-justified has-text-left">On top of that, we consider also the safety of the infrastrure and the facilities (hospitals etc..)
                  </p>
                  <h4><a id='sun_friendly_months'>How we define the suitable months for sun</a></h4>
                  <p class="has-text-justified has-text-left">
                    We gather information about minimum temperatures, maxiumum temperatures for each months from multiple sources and do an average.
                    Then, we do the same for the number of rainy days on average during each month of the year
                  </p>
                  <p class="has-text-justified has-text-left">
                    From the information we gathered, we defined each month as:
                  </p>
                  <p class="has-text-justified has-text-left">
                    <ul>
                      <li>Sun Guaranteed: the average of max and min temperatures is higher than 20 degrees Celsius, lower than 33 degrees and there are less than 4 rainy days during the month</li>
                      <li>Sun not Guaranteed but likely: the average of max and min temperatures is higher than 18 degrees Celsius, lower than 33 degrees and there are less than 7 rainy days during the month </li>
                      <li>Sunny Weather very unlikely: if it is none of the 2 previous options</li>
                    </ul>
                  </p>
                  <br />
                  <br />
                  <p class="has-text-justified has-text-left">
                    If you need more information, please reach out to us on Social Medias (More information on the <a href="/help" >Help page</a>)
                  </p>

                </div>
              </div>
            </div>
          </div>
          <div class="column is-4 is-narrow">

            <div class="card-wrapper">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    Subscribe
                  </p>
                </header>
                <div class="card-content">
                  <div class="content">
                    <p>Subscribe to our Newsletter to get news about Sol Passport and amazing deals!</p>
                    <form>
                      <MailingList style="fullsize" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-wrapper">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    Social Medias
                  </p>
                </header>
                <div class="card-content">

                  <SocialMedias />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AddProperty() {
  return (
    <span>
      <h1>How to Add your property to Sol Passport</h1>
      <h2>Added / Verified and Sponsored properties</h2>
      <h3>Added Properties</h3>
      <p class="has-text-justified has-text-left pl-5" >
        Hotels identified as family friendly by our community are being added to our website and our automatic on-boarding process applies. This is a process done based on information collected from internet (booking website, official website, hotel reviews etc...). Those hotels are being displayed on our website based on the user research. They are displayed after the verified hotels matching the same criteria and after the sponsored hotels that are "sun guaranteed" for the month searched.
      </p>
      <h3>Verified Properties</h3>
      <p class="has-text-justified has-text-left pl-5" >
        Verified hotels are tagged as so and users can filter to display only those. This features also a full review page of the hotel on our blog.
        Finally, instead of being calculated with our algorithm, the fff rating is done following our experience in the hotel. If in a research, 2 hotels have the same characteristics, the verified hotel will be displayed first.
      </p>
      <h3>Sponsored Properties</h3>
      <p class="has-text-justified has-text-left pl-5" >
        Sponsored hotels are displayed at the top of the research. For each research, only 2 sponsored hotels matching closely the research are displayed.
        Also, before any research, 2 random sponsored hotels are displayed.
      </p>
      <h2>Request your property to be added/verified or sponsored</h2>
      <p class="has-text-justified has-text-left pl-5">
        If you want your property to be added to Sol Passport, please contact us at admin@solpassport.com
      </p>
    </span>
  );
}

class App extends Component {

  render() {
    /*
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
      */
    return (
      <div className="App">
        <Navbar />
        <div className='main-content'>
          <Router>
            <Routes>
              <Route path='/' element={
                <Fragment>
                  <MainTabs />
                </Fragment>
              } />

              <Route path='about' element={
                <Fragment>
                  <About />
                </Fragment>
              } />

              <Route path='addyourproperty' element={
                <Fragment>
                  <AddProperty />
                </Fragment>
              } />

              <Route path='mailinglist' element={
                <RegisterSubscribers />
              } />

              <Route path='faq' element={
                <FAQs faqs={faqs} />
              } />

              <Route path='help' element={
                <Help />
              } />

              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Router>
        </div>
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { state };
}

export default connect(mapStateToProps)(App);