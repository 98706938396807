import React from 'react'

export default function Navbar() {
    return (
        <footer class="footer mt-6">
            <div class="content has-text-centered">
                <p>Copyright © Sol Passport</p>

            </div>

            <div class="columns">
                <div class="column">

                    <h4 class="bd-footer-title 
                 has-text-weight-medium
                 has-text-left">
                <strong>Sol Passport</strong>.
                    </h4>

                    <p class="bd-footer-link 
                has-text-left">
                        Sol Passport helps you to find in seconds the best resorts around the world 
                        with sun guaranteed at any time of the year!
                    </p>

                </div>

                <div class="column">
                    <h4 class="bd-footer-title 
                 has-text-weight-medium 
                 has-text-justify">
                        Hotel owners / Advertisers
                    </h4>

                    <p class="bd-footer-link">
                        <a href="/addyourproperty">
                            <span class="icon-text">
                                <span>List your property</span>
                            </span>
                        </a>
                        <br />
                    </p>

                </div>

                <div class="column">
                    <h4 class="bd-footer-title
                 has-text-weight-medium
                 has-text-justify">
                        Contact us
                    </h4>

                    <p class="bd-footer-link">
                        <a href="mailto: admin@solpassport.com" target="_blank">
                            <span class="icon-text">
                                <span>Email</span>
                            </span>
                        </a>
                        <br />
                        <a href="https://www.instagram.com/solpassport/" target="_blank">
                            <span class="icon-text">
                                <span>Instagram</span>
                            </span>
                        </a>
                        <br />
                        <a href="https://twitter.com/solpassport" target="_blank">
                            <span class="icon-text">
                                <span>Twitter</span>
                            </span>
                        </a>
                        <br />
                        <a href="https://www.pinterest.co.uk/solpassport/" target="_blank">
                            <span class="icon-text">
                                <span>Pinterest</span>
                            </span>
                        </a>
                    </p>

                </div>
            </div>
        </footer>)
}