/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
function shuffle_array (arr) {
    //return arr;
    var j, x, index;
    for (index = arr.length - 1; index > 0; index--) {
        j = Math.floor(Math.random() * (index + 1));
        x = arr[index];
        arr[index] = arr[j];
        arr[j] = x;
    }
    return arr;
  }

module.exports =  { shuffle_array }