import React from 'react';
import { connect } from 'react-redux';

import HolidaysGrid from '../HolidaysGrid'

import RegisterSubscribers from '../RegisterSubscribers'
import FAQs from '../FAQs'

import './tabs.css';

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { setRiskyTag, filterByValue, setTypeGetaway, sortBySOLRating, sortByBudget, sortByAlphabet, sortByTravelTime, filterByMonth } from "../../store";
import MailingList from '../MailingList';

const faqs = require('../../data/faqs.json')


class MainTabs extends React.Component {

  setRiskyTag(allow_risk) {
    this.props.dispatch(setRiskyTag(allow_risk))
  }

  filterByInput(e) {
    let input = e.target.value;
    this.props.state.current_filter_input = input;
    this.props.dispatch(filterByValue({ value: input }))
  }


  sortByInput(e) {
    let value = e.target.value;
    let direction = value.endsWith('asc') ? "asc" : "desc";

    this.props.state.sort_by_input = value;

    if (value.startsWith('travel_time')) {
      this.props.dispatch(sortByTravelTime({ direction }))
    } else if (value.startsWith("sol_rating")) {
      this.props.dispatch(sortBySOLRating({ direction }));
    } else if (value.startsWith("budget")) {
      this.props.dispatch(sortByBudget({ direction }));
    } else {
      this.props.dispatch(sortByAlphabet({ direction }));
    }
  }

  selectTypeGetaway(e) {
    let value = e.target.value;
    this.props.dispatch(setTypeGetaway({ value }))

  }
  selectMonth(e) {
    let value = e.target.value;
    this.props.state.month_selected = value;
    this.props.state.sort_by_input = ""
    this.props.state.current_filter_input = ""
    this.props.dispatch(filterByMonth({ value }))
  }

  render() {
    let allow_risky = this.props.state.allow_risky;

    function SelectionTab() {
      let favorites = localStorage.favorites
      if (favorites) favorites = JSON.parse(favorites)
      else favorites = []

      if (favorites.length == 0) {
        return (
          <section>
            <p>Click on the "heart" icon on any holiday in the "Find" tab in order to select it.</p>
          </section>
        )
      } else {
        return (
          <section class="section pt-0">
            <h3>Register to get updates on your selected holidays.</h3>
            <p class="has-text-centered">
              Register and receive weekly the best deals for these hotels or similar ones.
              <br />We use AI to analyse your selection and find the best deals that matches your requirements.
            </p>
            <p className='has-text-centered'>
              <b>Never miss the best holiday deals anymore!</b>
            </p>
            <RegisterSubscribers />
            <p className='pull-right pt-5 pr-0'><i>You can unsubscribe at any time.</i></p>
          </section>
        )
      }
    }

    return (
      <Tabs className="Tabs pt-3">
        <TabList>
          <Tab>Family</Tab>
          <Tab>Adults Only</Tab>
          <Tab>Selection</Tab>
        </TabList>
        <TabPanel>
          <section className='section is-tiny'>
            <fieldset class="fieldset">
              <legend>Find Only Resorts <br />Where It Is HOT and SUNNY!<span style={{ color: "hsl(48, 100%, 67%)", "padding-right": "3px" }}> <i class="fas fa-sun" title="sun"></i></span></legend>
              <div class="columns is-desktop">

                {/*<div class="column">
                  <div class="field">
                    <label class="label">Kind of holidays</label>
                    <span class="control is-expanded has-icons-left">
                      <div className="select">
                        <span class="icon is-small is-left">
                          <i class="fas fa-globe fa-fw"></i>
                          
                        </span>
                        <span class="select is-primary is-normal">
                          <select onChange={e => {
                            this.selectTypeGetaway(e)
                          }} value={this.props.state.kind_getaway} >
                            <option value="" disabled selected defaultValue>What kind of getaways?</option>
                            <option value='family'>Family</option>
                            <option value='adults'>Adults Only</option>
                          </select>
                        </span>
                      </div>

                    </span>
                    <span class="help">Filter for the right kind of getaways</span>
                  </div>
                </div>*/}
                <div class="column">
                  <div class="field">
                    <label class="label">Traveling month</label>
                    <span class="control is-expanded has-icons-left">
                      <div className="select">
                        <span class="icon is-small is-left">
                          <i class="fas fa-sun fa-fw"></i>
                        </span>
                        <span class="select is-primary is-normal">
                          <select onChange={e => {
                            this.selectMonth(e)
                          }} value={this.props.state.month_selected} >
                            <option value="" disabled selected defaultValue>When do you want to Travel?</option>

                            <option value='january'>January</option>
                            <option value='february'>February</option>
                            <option value='march'>March</option>
                            <option value='april'>April</option>
                            <option value='may'>May</option>
                            <option value='june'>June</option>
                            <option value='july'>July</option>
                            <option value='august'>August</option>
                            <option value='september'>September</option>
                            <option value='october'>October</option>
                            <option value='november'>November</option>
                            <option value='december'>December</option>
                            <option value='any'>Any Month</option>
                          </select>
                        </span>
                      </div>

                    </span>
                    <span class="help">Filter for destinations with sunny weather guaranteed during the month</span>
                  </div>
                </div>
                <div class="column">

                  <div class="field">
                    <label class="label">Take some risk with the weather?</label>
                    <div class="control">

                      <label class="radio">
                        <input type="radio" className='is-primary' name="not_always_sunny" onChange={e => { this.setRiskyTag(true) }} checked={allow_risky ? 'checked' : ''} />
                        Yes
                      </label>
                      <label class="radio">
                        <input type="radio" className='is-primary' name="always_sunny" onChange={e => { this.setRiskyTag(false) }} checked={allow_risky ? '' : 'checked'} />
                        No
                      </label>
                      <span class="help">Non-sunny destinations for the selected month are consistently excluded but, borderline ones may be considered.</span>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

          </section>
          <section>

            <div className='columns has-text-centered'>
              <div className='column pl-5 pr-5 ' style={{ "max-width": "550px" }}>
                <span className='has-text-centered'>


                  <div class="control has-icons-left">
                    <input
                      class="input"
                      placeholder="Find a specific destination or resort"
                      onChange={e => {
                        this.filterByInput(e);
                      }}
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-filter"></i>
                    </span>
                  </div>
                  {/*<span class="help">Look for a specific hotel</span>*/}
                </span>
              </div>
              <div className='column'>

                <div class="field" style={{ "text-align": "right", "padding-right": "15px" }}>
                  <span class="control is-expanded has-icons-left">

                    <div className="select is-normal">
                      <span class="icon is-small is-left">
                        <i class="fas fa-sort"></i>
                      </span>
                      <select onChange={e => {
                        this.sortByInput(e)
                      }} value={this.props.state.sort_by_input}>
                        <option value="" disabled selected defaultValue>Sort by</option>


                        <option value='travel_time_asc'>Travel time - Shortest to Longest</option>
                        <option value='sol_rating_desc'>Our Rating - Best to Worst rated</option>
                        <option value='budget_asc'>Budget Rating - Least to Most Expensive</option>
                        <option value='alphabet_asc'>Hotel Name - A-Z</option>
                        <option value='alphabet_desc'>Hotel Name - Z-A</option>

                      </select>

                    </div>
                  </span>
                  <span class="help">Sort by Travel time, Rating or Budget ...</span>
                </div>
              </div>
            </div>

          </section>
          <HolidaysGrid />
        </TabPanel>
        <TabPanel>
          <h2>Adults resorts</h2>
          <p className='has-text-centered'>Come back soon to discover amazing year-round sunny resorts for Adults only!</p>
          <MailingList />
        </TabPanel>
        <TabPanel>
          <h2>Your Selected Holidays</h2>
          <SelectionTab />
          <h3>Currently selected holidays</h3>
          <HolidaysGrid options={{ "show_selected_only": true, "simplified_view": true }} />
        </TabPanel>
      </Tabs>
    )
  }
}


function mapStateToProps(state) {
  return { state };
}

export default connect(mapStateToProps)(MainTabs);