import {shuffle_array} from './helper_functions'

let holidays_packs = require('./holidays_data.json')
let destinations = require('./destinations.json')
let facilities_kids_friendliness = require('./facilities_kids_friendliness.json')
let highlights_facilities = require('./highlights_facilities.json')

export const destinations_data = destinations

function generate(count) {
  let data = holidays_packs;
  let destinations_obj = {}

  destinations.forEach(destination => {
    destinations_obj[destination.name] = destination
  })

  let hol_data = data.map(elmt => {
    elmt.travel_time = parseInt(elmt.transfer_time) + parseInt(destinations_obj[elmt.destination].flying_from_london)
    elmt.facilities = elmt.all_facilities.map(facility => {
      let facility_notation = facilities_kids_friendliness.find(a => a.name.trim().toLowerCase() == facility.trim().toLowerCase())

      let notation = facility_notation.is_kids_friendly == "Y" ? parseInt(facility_notation["scale_kids_friendliness"]) : 0;


      return { name: facility, notation }
    })
    elmt.facilities = elmt.facilities.filter(facility => highlights_facilities.find(fac => fac["booking.com name"].trim().toLowerCase() == facility.name.trim().toLowerCase()) !== undefined)
    elmt.facilities = elmt.facilities.map(facility => {
      let fac_highlight = highlights_facilities.find(fac => fac["booking.com name"].trim().toLowerCase() == facility.name.trim().toLowerCase())

      return {
        ...facility,
        icon: fac_highlight.icon,
        label: fac_highlight['label']
      }
    })

    elmt.facilities = elmt.facilities.filter(function(item, pos) {
        return elmt.facilities.map(a => a.label).indexOf(item.label) == pos;
    })
    
    elmt.facilities = shuffle_array(elmt.facilities).sort((a, b) => b.notation - a.notation).slice(0, 4)

    return elmt
  })


  return { holidays: hol_data, destinations_obj };
}

export default generate;