import React from 'react'
import './style.css'

export default function Navbar() {
  const [isActive, setisActive] = React.useState(false)

  return (

    <section class="hero is-small">

      <div class="hero-head">
        <nav className='navbar' role='navigation' aria-label='main navigation'>
          <div className='navbar-brand'>
            <span className='ml-4 mt-2 header-logo'><a href="/"> <img src="./logo-no-background.png" width="250" height="18" /></a>
            </span>

            <a
              onClick={() => {
                setisActive(!isActive)
              }}
              role='button'
              className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
              aria-label='menu'
              aria-expanded='false'
              data-target='navbarBasicExample'
            >
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </a>
          </div>
          <div id='navbarBasicExample' className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
            <div className='navbar-end'>
              <div className='navbar-item'>
                <a href='/' className='navbar-item'>
                  Home
                </a>
                <a href='/faq' className='navbar-item'>
                  FAQ
                </a>
                <a href='/help' className='navbar-item'>
                  Help
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/*<div class="hero-body">
        <p class="title">
          Destinations & Resorts Finder
        </p>
        <p class="subtitle">
          Find very quickly destinations and resorts with our innovative search engine. <br/>
          Choose Family Friendly or Adults Only holidays and then, select the month of departure. 
          The system will then provide a list of hand picked resorts in destinations guaranteed to be sunny during this month. 
        </p>
        </div>*/}
    </section>


  )
}