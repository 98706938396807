import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';


class RegisterSubscribers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message,
            email: this.props.email,
            status: "idle",
            time_travel: null
        }
    }
    /*
        setEmail() {
            console.log("this.props.count");
            console.log(this.props.email);
            let email = this.state.email
            this.setState({ email })
        }
    */

    setStatus(status) {
        this.setState({ ...this.state, status })
    }
    setMessage(message) {
        this.setState({ ...this.state, message, status: "idle" })
    }
    setEmail(email) {
        this.setState({ ...this.state, email, message: "" })
    }
    setTimeTravel(time_travel) {
        this.setState({ ...this.state, time_travel, message: "" })
    }
    setFamilySize(family_size) {
        this.setState({ ...this.state, family_size, message: "" })
    }

    render() {
        let message = this.state.message
        let email = this.state.email
        let holidays = localStorage.favorites || []
        let time_travel = this.state.time_travel
        let family_size = this.state.family_size
        let frequency = 'weekly'

        const handleSubscribe = async () => {

            try {
                if (!time_travel) return this.setMessage("Please specify a time to travel")
                //if (!family_size) return this.setMessage("Please specify a family size")
                if (!email) return this.setMessage("Please specify an email")

                this.setStatus("inprogress")
                const response = await axios.post(
                    'https://fff-backend-vercel.vercel.app/api/subscribers/addSubscriber',
                    {
                        email,
                        holidays,
                        frequency,
                        time_travel
                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    }
                );

                this.setMessage('Thanks for subscribing!')

                localStorage.setItem('is_mailing_list_sub', "true");

                console.log('Subscriber added:', response.data);
            } catch (error) {
                this.setMessage('Please check your email and retry.')
                console.error('Error adding subscriber:', error);
            }
        };

        return (
            <div class="columns mt-5 is-centered">
                <div class="rows">
                    {/*<div class="row">
                        <div class="columns">
                            <div class="column">
                                <div class="field is-horizontal">
                                    <div class="field-body ml-5 mr-5">
                                        <div class="select is-primary">
                                            <select>
                                                <option value="" disabled selected defaultValue>How many adults?</option>
                                                <option value='1'>1 adult</option>
                                                <option value='2'>2 adults</option>
                                                <option value='3'>3 adults</option>
                                                <option value='4'>4 adults</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field is-horizontal">
                                    <div class="field-body ml-5 mr-5">
                                        <div class="select is-primary">
                                            <select>
                                                <option value="" disabled selected defaultValue>How many Kids?</option>
                                                <option value='0'>0 kid</option>
                                                <option value='1'>1 kid</option>
                                                <option value='2'>2 kids</option>
                                                <option value='3'>3 kids</option>
                                                <option value='4'>4 kids</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="field is-horizontal">
                            <div class="field-body ml-5 mr-5 mt-5">
                                <div class="select is-multiple is-primary is-normal">
                                    <select multiple size="8">
                                        <option value="" disabled selected defaultValue>When do you want to travel?</option>
                                        <option value='january'>January</option>
                                        <option value='february'>February</option>
                                        <option value='march'>March</option>
                                        <option value='april'>April</option>
                                        <option value='may'>May</option>
                                        <option value='june'>June</option>
                                        <option value='july'>July</option>
                                        <option value='august'>August</option>
                                        <option value='september'>September</option>
                                        <option value='october'>October</option>
                                        <option value='november'>November</option>
                                        <option value='december'>December</option>
                                        <option value='any'>Any Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div class="row">
                        <div class="columns">
                            {/*<div class="column">
                                <div class="field is-horizontal">
                                    <div class="field-body ml-5 mr-5">
                                        <div class="field has-addons is-fullwidth">
                                            <div class="control has-icons-left">

                                                <div class="select is-primary is-normal">
                                                    <select
                                                        onChange={e => {
                                                            this.setFamilySize(e.target.value);
                                                        }}>
                                                        <option value="" disabled selected defaultValue>What is the size of your Family?</option>
                                                        <option value='2'>Couple</option>
                                                        <option value='3'>Family of Three</option>
                                                        <option value='4'>Family of Four</option>
                                                        <option value='5'>Family of Five</option>
                                                        <option value='6'>Family of Six</option>
                                                        <option value='7'>Family of Seven</option>
                                                    </select>
                                                </div>
                                                <div class="icon is-small is-left">
                                                    <i class="fas fa-child"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    </div>*/}
                            <div class="column is-centered">
                                <div class="field is-horizontal">
                                    <div class="field-body ml-5 mr-5">

                                        <div class="field has-addons is-fullwidth">
                                            <div class="control has-icons-left">
                                                <div class="select is-primary is-normal">
                                                    <select
                                                        onChange={e => {
                                                            this.setTimeTravel(e.target.value);
                                                        }}>
                                                        <option value="" disabled selected defaultValue>When do you plan to travel?</option>
                                                        <option value='anytime'>Any time</option>
                                                        <option value='uk_holidays'>Only during the UK school holidays</option>
                                                        <option value='not_uk_holidays'>Certainly NOT during the UK school holidays</option>
                                                    </select>
                                                </div>
                                                <div class="icon is-small is-left">
                                                    <i class="fas fa-globe"></i>
                                                </div>
                                            </div></div>
                                    </div>
                                </div>
                            </div>

                            <div className='column'>
                            <div class="field-body ml-5 mr-5 mt-0">
                                    <div class="field has-addons is-fullwidth">
                                        <div class={"control is-expanded has-icons-left has-icons-right " + (this.state.status === "inprogress" ? "is-loading" : "")}>
                                            <input
                                                class="input is-primary"
                                                type="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => this.setEmail(e.target.value)}
                                            />
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-envelope"></i>
                                            </span>
                                        </div>
                                        <div class="control">
                                            <button
                                                onClick={handleSubscribe}
                                                className="button is-primary"
                                                disabled={this.state.status === "inprogress" ? true : false}
                                            >Subscribe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div class="row">
                        <div class="columns is-centered">
                            <div class="column" style={{"max-width":"550px"}}>
                                
                            </div>

                        </div>
                    </div>
                                                    */}
                    <div class="row">
                        <span>{message}</span>
                    </div>
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return { state };
}


export default connect(mapStateToProps)(RegisterSubscribers);
