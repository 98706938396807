import React from "react";
import { connect } from 'react-redux';
import { changeModalState } from "../../store";
import RegisterSubscribers from '../RegisterSubscribers'

import fontawesome from '@fortawesome/fontawesome'
import { faInfoCircle, faCheckSquare, faCoffee, faUser, faStarHalf, faStar, faDollarSign, faPlus, faPlane, faBuilding } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '@creativebulma/bulma-divider/dist/bulma-divider.min.css'

fontawesome.library.add(faInfoCircle, faCheckSquare, faCoffee, faUser, faStarHalf, faStar, faDollarSign, faPlus, faPlane, faBuilding);


class ModalBookTrip extends React.Component {
    state = {
        isModal: false
    };

    switchModal = (modal_name) => {
        this.props.dispatch(changeModalState({ modal_name }));
    };

    render() {

        const is_mailing_list_sub = localStorage.getItem('is_mailing_list_sub');

        const anchor = this.props.anchor;
        const name = this.props.name;
        const booking_link = this.props.booking_link;
        const skyscanner_link = this.props.skyscanner_link;
        let active_modal = this.props.state.activeModal;

        const active = (anchor === active_modal) ? "is-active" : "";

        function MailingList() {
            if (!is_mailing_list_sub) {
                return (<div>
                    <h2 class="mt-0">Subscribe to updates</h2>
                    <div class="content pb-5">
                        <p>Register to the mailing list and get deals on Hotels like "{name}"</p>
                        <RegisterSubscribers />
                    </div><div>
                        <div class="divider">And Book Your Trip </div>
                    </div>
                </div>)
            }
            return (<span></span>)
        }

        return (
            <div>
                <div className={`modal_subscribe modal ${active}`}>
                    <div className="modal-background" />
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Book your Trip</p>
                            <button
                                onClick={e => {
                                    this.switchModal(name);
                                }}
                                className="delete"
                                aria-label="close"
                            />
                        </header>
                        <section class="modal-card-body">
                            <MailingList />
                            <div class="content">

                                <div class='rows'>
                                    <div class='row is-full'>
                                        <div class="column has-ribbon-right has-ribbon-bottom">
                                            <div className="ribbon is-info is-small">With Booking.com</div>
                                            <a href={booking_link} class="button is-link is-medium is-fullwidth has-text-weight-semibold" ><FontAwesomeIcon icon="building" pull="left" />Book Your Stay To {name.split(" ")[0]}</a>
                                        </div>
                                    </div>
                                    <div class='row is-full'>
                                        <div class="column   has-ribbon-right has-ribbon-bottom">
                                            <div className="ribbon is-info is-small">With Skyscanner</div>
                                            <a href={skyscanner_link} class="button is-primary is-medium is-fullwidth has-text-weight-semibold" target="_blank"><FontAwesomeIcon icon="plane" pull="left" />Book Your Flight
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <p class="is-size-7 pt-0 pb-2">* We are affiliates Booking.com</p>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return { state };
}

export default connect(mapStateToProps)(ModalBookTrip);