import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';


class MailingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email
        }
    }

    setEmail(email) {
        this.setState({ ...this.state, email, message: "" })
    }

    setMessage(message) {
        this.setState({ ...this.state, message, status: "idle" })
    }

    setStatus(status) {
        this.setState({ ...this.state, status })
    }

    render() {
        let message = this.state.message
        let email = this.state.email
        let style = this.props.style
        let active = true

        const handleSubscribe = async () => {

            try {
                if (!email) return this.setMessage("Please specify an email")

                this.setStatus("inprogress")
                const response = await axios.post(
                    'https://fff-backend-vercel.vercel.app/api/mailinglist/add',
                    {
                        email,
                        active
                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    }
                );

                this.setMessage('Thanks for subscribing!')

                console.log('Subscriber added:', response.data);
            } catch (error) {
                this.setMessage('Please check your email and retry.')
                console.error('Error adding subscriber:', error);
            }
        };

        if (style == "fullsize") {
            return (
                <>
                    <div class="columns is-multiline">
                        <div class="column">
                            <div class={"control is-expanded has-icons-left has-icons-right " + (this.state.status === "inprogress" ? "is-loading" : "")}>
                                <input
                                    class="input is-primary"
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => this.setEmail(e.target.value)}
                                />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-envelope"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-centered">
                        <div class="column p-0 is-centered">
                        <button
                            onClick={handleSubscribe}
                            className="button is-primary"
                            disabled={this.state.status === "inprogress" ? true : false}
                        >Subscribe</button>
                        </div>
                    </div>
                    <div class="row">
                        <span>{message}</span>
                    </div>
                </>

            )
        } else {
            return (
                <div class="columns mt-5 is-centered">

                    <div className='column is-half'>
                        <div class="field-body ml-5 mr-5 mt-0">
                            <div class="field has-addons is-fullwidth">
                                <div class={"control is-expanded has-icons-left has-icons-right " + (this.state.status === "inprogress" ? "is-loading" : "")}>
                                    <input
                                        class="input is-primary"
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => this.setEmail(e.target.value)}
                                    />
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-envelope"></i>
                                    </span>
                                </div>
                                <div class="control">
                                    <button
                                        onClick={handleSubscribe}
                                        className="button is-primary"
                                        disabled={this.state.status === "inprogress" ? true : false}
                                    >Subscribe</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <span>{message}</span>
                        </div>
                    </div>
                </div>
            );
        }
    }
}



function mapStateToProps(state) {
    return { state };
}


export default connect(mapStateToProps)(MailingList);
