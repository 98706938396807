import generate from "../helpers/data";
import { destinations_data } from "../helpers/data";
import {shuffle_array} from '../helpers/helper_functions'

const initialState = {
    appliedFilters: [],
    activeModal: "None",
    allow_risky: false
};

const SORT_BY_ALPHABET = "SORT_BY_ALPHABET";
const SORT_BY_TRAVEL_TIME = "SORT_BY_TRAVEL_TIME";
const SORT_BY_BUDGET = "SORT_BY_BUDGET";
const SORT_BY_SOL_RATING = "SORT_BY_SOL_RATING";
const LOAD_DATA = "LOAD_DATA";
const FILTER_BY_PRICE = "FILTER_BY_PRICE";
const FILTER_BY_VALUE = "FILTER_BY_VALUE";
const LOAD_NEW_PAGE = "LOAD_NEW_PAGE";
const LOAD_EXACT_PAGE = "LOAD_EXACT_PAGE";
const SET_FAVORITE = "SET_FAVORITE";
const FILTER_BY_MONTH = "FILTER_BY_MONTH";
const CHANGE_MODAL_STATE = "CHANGE_MODAL_STATE";
const SET_RISKY = "SET_RISKY"
const SET_TYPE_GETAWAY = "SET_TYPE_GETAWAY"

export const sortByTravelTime = payload => ({
    type: SORT_BY_TRAVEL_TIME,
    payload
});
export const sortByBudget = payload => ({
    type: SORT_BY_BUDGET,
    payload
});
export const sortBySOLRating = payload => ({
    type: SORT_BY_SOL_RATING,
    payload
});

export const filterByPrice = payload => ({
    type: FILTER_BY_PRICE,
    payload
});

export const filterByValue = payload => ({
    type: FILTER_BY_VALUE,
    payload
});

export const sortByAlphabet = payload => ({
    type: SORT_BY_ALPHABET,
    payload
});

export const filterByMonth = payload => ({
    type: FILTER_BY_MONTH,
    payload
});

export const loadData = (payload) => ({
    type: LOAD_DATA,
    payload
});

export const setTypeGetaway = (payload) => ({
    type: SET_TYPE_GETAWAY,
    payload
});

export const loadNewPage = (payload) => ({
    type: LOAD_NEW_PAGE,
    payload
});

export const loadExactPage = (payload) => ({
    type: LOAD_EXACT_PAGE,
    payload
});

export const setHotelAsFavorite = (payload) => ({
    type: SET_FAVORITE,
    payload
})

export const setRiskyTag = (payload) => ({
    type: SET_RISKY,
    payload
})

export const changeModalState = (payload) => ({
    type: CHANGE_MODAL_STATE,
    payload
})


let apply_month_filter = (new_state) => {
    let destinations_to_keep = []
    let month_value = new_state.month_selected
    let allow_risky = new_state.allow_risky

    if (!month_value) return new_state

    destinations_data.forEach(destination => {
        if (month_value === "any" ||
            destination["weather_" + month_value].toLowerCase() === "green" ||
            (allow_risky && destination["weather_" + month_value].toLowerCase() === "orange")) {
            destinations_to_keep.push(destination.name)
        }
    })

    let currentFilters = new_state.appliedFilters;
    let filtered_holidays = new_state.holidays.filter(holiday => destinations_to_keep.indexOf(holiday.destination) >= 0)
    
    /*
    filtered_holidays = filtered_holidays.sort((holiday_a, holiday_b) => {
        return parseFloat(holiday_b["sol_rating"]) - parseFloat(holiday_a["sol_rating"])
    });*/

    filtered_holidays = shuffle_array(filtered_holidays)

    if (currentFilters.length > 0 && currentFilters.indexOf(SORT_BY_TRAVEL_TIME) > 0) {
        sortAsc(filtered_holidays, 'travel_time');
    }

    if (month_value !== "any") {
        currentFilters = addFilterIfNotExists(FILTER_BY_MONTH, currentFilters);

        new_state.filteredHolidays = filtered_holidays;
        new_state.filteredCount = new_state.filteredHolidays.length;
        new_state.filteredPages = Math.ceil(new_state.filteredCount / new_state.countPerPage);

    } else {
        currentFilters = removeFilter(FILTER_BY_MONTH, currentFilters);

        if (currentFilters.length === 0) {
            new_state.filteredHolidays = new_state.holidays;
            new_state.filteredCount = new_state.filteredHolidays.length;
            new_state.filteredPages = Math.ceil(new_state.filteredCount / new_state.countPerPage);
        }
    }

    new_state.appliedFilters = currentFilters

    return new_state
}

const filterStore = (state = initialState, action) => {
    switch (action.type) {
        case SET_TYPE_GETAWAY:
            let getaway_state = Object.assign({}, state);
            getaway_state.kind_getaway = action.payload.value;
            return getaway_state
        case FILTER_BY_MONTH:
            let new_state = Object.assign({}, state);
            new_state.month_selected = action.payload.value;
            return apply_month_filter(new_state);
        case CHANGE_MODAL_STATE:
            let modalsState = Object.assign({}, state);

            if (modalsState.activeModal === action.payload.modal_name)
                modalsState.activeModal = "None"
            else
                modalsState.activeModal = action.payload.modal_name

            return modalsState
        case SET_FAVORITE:
            let setFavoritesState = Object.assign({}, state);

            var favorites = []
            if(localStorage.favorites) favorites = JSON.parse(localStorage.favorites);

            setFavoritesState.holidays.forEach(holiday => {
                if (holiday.name === action.payload.hotel_name) {
                    holiday.is_favorite = !holiday.is_favorite;
                    let elmt_fav_pos = favorites.indexOf(action.payload.hotel_name)
                    if(holiday.is_favorite && elmt_fav_pos < 0)
                        favorites.push(action.payload.hotel_name)
                    else if(elmt_fav_pos > -1) {
                        favorites.splice(elmt_fav_pos, 1);
                    }
                }
            })

            localStorage.favorites = JSON.stringify(favorites);

            return setFavoritesState
        case SET_RISKY:
            let setRiskyState = Object.assign({}, state);

            setRiskyState.allow_risky = action.payload
            //if(setRiskyState.month_selected)
            //    return filterByMonth({value:setRiskyState.month_selected})
            //else 
            return apply_month_filter(setRiskyState)
        case SORT_BY_ALPHABET:
            const sortByAlphabetState = Object.assign({}, state);
            let sortedAlphabetArr = action.payload.direction === "asc" ?
                sortAsc(state.filteredHolidays, 'name') :
                sortDesc(state.filteredHolidays, 'name');

            sortByAlphabetState.filteredHolidays = sortedAlphabetArr;
            sortByAlphabetState.appliedFilters = addFilterIfNotExists(SORT_BY_ALPHABET, sortByAlphabetState.appliedFilters);

            sortByAlphabetState.appliedFilters = removeFilter(SORT_BY_TRAVEL_TIME, sortByAlphabetState.appliedFilters);
            sortByAlphabetState.appliedFilters = removeFilter(SORT_BY_BUDGET, sortByAlphabetState.appliedFilters);
            sortByAlphabetState.appliedFilters = removeFilter(SORT_BY_SOL_RATING, sortByAlphabetState.appliedFilters);

            return sortByAlphabetState;
        case SORT_BY_BUDGET:
            let sortByBudgetState = Object.assign({}, state);

            let sortedBudgetArr = action.payload.direction === "asc" ?
                sortAsc(state.filteredHolidays, 'budget_with_flight') :
                sortDesc(state.filteredHolidays, 'budget_with_flight');

            sortByBudgetState.filteredHolidays = sortedBudgetArr;

            sortByBudgetState.appliedFilters = removeFilter(SORT_BY_TRAVEL_TIME, sortByBudgetState.appliedFilters);
            sortByBudgetState.appliedFilters = removeFilter(SORT_BY_SOL_RATING, sortByBudgetState.appliedFilters);
            sortByBudgetState.appliedFilters = removeFilter(SORT_BY_ALPHABET, sortByBudgetState.appliedFilters);

            return sortByBudgetState;
        case SORT_BY_SOL_RATING:
            let sortBySOLRatingState = Object.assign({}, state);

            let sortedSOLArr = action.payload.direction === "asc" ?
                sortAsc(state.filteredHolidays, 'sol_rating') :
                sortDesc(state.filteredHolidays, 'sol_rating');

            sortBySOLRatingState.filteredHolidays = sortedSOLArr;

            sortBySOLRatingState.appliedFilters = removeFilter(SORT_BY_BUDGET, sortBySOLRatingState.appliedFilters);
            sortBySOLRatingState.appliedFilters = removeFilter(SORT_BY_TRAVEL_TIME, sortBySOLRatingState.appliedFilters);
            sortBySOLRatingState.appliedFilters = removeFilter(SORT_BY_ALPHABET, sortBySOLRatingState.appliedFilters);

            return sortBySOLRatingState;
        case SORT_BY_TRAVEL_TIME:
            let sortByTravelTimeState = Object.assign({}, state);
            let sortedTravelTimeArr = action.payload.direction === "asc" ?
                sortAsc(state.filteredHolidays, 'travel_time') :
                sortDesc(state.filteredHolidays, 'travel_time');

            sortByTravelTimeState.filteredHolidays = sortedTravelTimeArr;
            sortByTravelTimeState.appliedFilters = addFilterIfNotExists(SORT_BY_TRAVEL_TIME, sortByTravelTimeState.appliedFilters);
            //sortByTravelTimeState.appliedFilters = removeFilter(SORT_BY_TRAVEL_TIME, sortByTravelTimeState.appliedFilters);

            sortByTravelTimeState.appliedFilters = removeFilter(SORT_BY_BUDGET, sortByTravelTimeState.appliedFilters);
            sortByTravelTimeState.appliedFilters = removeFilter(SORT_BY_SOL_RATING, sortByTravelTimeState.appliedFilters);
            sortByTravelTimeState.appliedFilters = removeFilter(SORT_BY_ALPHABET, sortByTravelTimeState.appliedFilters);

            return sortByTravelTimeState;
        case FILTER_BY_PRICE:
            //filter by price
            return state;
        case FILTER_BY_VALUE:
            let newState = Object.assign({}, state);
            let value = action.payload.value;

            let filteredHotelValues = state.holidays.filter(holiday => {
                return holiday.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
            });

            let filteredDestinationValues = state.holidays.filter(holiday => {
                return holiday.destination.toLowerCase().indexOf(value.toLowerCase()) >= 0
            });

            let filteredValues = filteredHotelValues.concat(filteredDestinationValues)

            let appliedFilters = state.appliedFilters;

            if (value) {
                appliedFilters = addFilterIfNotExists(FILTER_BY_VALUE, appliedFilters);

                newState.filteredHolidays = filteredValues;
                newState.filteredCount = newState.filteredHolidays.length;
                newState.filteredPages = Math.ceil(newState.filteredCount / newState.countPerPage);

            } else {
                appliedFilters = removeFilter(FILTER_BY_VALUE, appliedFilters);

                if (appliedFilters.length === 0) {
                    newState.filteredHolidays = newState.holidays;
                    newState.filteredCount = newState.filteredHolidays.length;
                    newState.filteredPages = Math.ceil(newState.filteredCount / newState.countPerPage);
                }
            }
            return newState;
        case LOAD_DATA:
            // Temporary setting large default. No pagination yet as not needed. To be reviewed later
            let count = action.payload.count || 50;
            let countPerPage = action.payload.countPerPage || 50;

            const queryParameters = new URLSearchParams(window.location.search)
            const hotel_name = queryParameters.get("hotel_name")

            //round up
            let totalPages = Math.ceil(count / countPerPage);

            let { holidays, destinations_obj } = generate(count);
                        
            var favorites = []
            if(localStorage.favorites) favorites = JSON.parse(localStorage.favorites);

            holidays.forEach(holiday => {
                if (favorites.indexOf(holiday.name) >= 0 && !holiday.is_favorite) {
                    holiday.is_favorite = true
                }
            })

            /*
            holidays.sort((holiday_a, holiday_b) => {
                return parseFloat(holiday_b["sol_rating"]) - parseFloat(holiday_a["sol_rating"])
            });*/

            holidays = shuffle_array(holidays)

            let filteredHolidays = holidays

            if (hotel_name) {
                let find_index = filteredHolidays.findIndex(a => a.name.toLowerCase().indexOf(hotel_name.toLowerCase()) >= 0)
                if (find_index >= 0) {
                    let holiday_found = JSON.parse(JSON.stringify(filteredHolidays[find_index]))
                    filteredHolidays.splice(find_index, 1)
                    filteredHolidays.unshift(holiday_found)
                }
            }

            return {
                ...state,
                holidays,
                filteredHolidays: filteredHolidays.slice(0, countPerPage),
                destinations: destinations_obj,
                currentCount: countPerPage,
                countPerPage,
                totalCount: count,
                currentPage: 1,
                totalPages: totalPages,
                filteredPages: totalPages
            };
        case LOAD_NEW_PAGE:
            //Clone the previous state
            let loadNewPageState = Object.assign({}, state);
            //How many pages should be added. Will always be 1 or -1
            let addPages = action.payload.page;
            //add it to the current
            loadNewPageState.currentPage += addPages;

            let perPage = loadNewPageState.countPerPage; //20 by default

            let nextHolidays;
            if (addPages === 1) {
                //Moving from page 1 to 2 will cause ‘upperCount’ to be 40
                let upperCount = loadNewPageState.currentCount + perPage;
                let lowerCount = loadNewPageState.currentCount; //This hasn’t been changed. It will remain 20.

                loadNewPageState.currentCount += loadNewPageState.countPerPage;
                nextHolidays = loadNewPageState.holidays.slice(lowerCount, upperCount);
            }

            if (addPages === -1) {
                let upperCount = loadNewPageState.currentCount; //40
                let lowerCount = loadNewPageState.currentCount - perPage; //20

                loadNewPageState.currentCount -= loadNewPageState.countPerPage;
                nextHolidays = loadNewPageState.holidays.slice(lowerCount - perPage, upperCount - perPage);
            }

            loadNewPageState.filteredHolidays = nextHolidays;
            // Don't use window.history.pushState() here in production
            // It's better to keep redirections predictable
            window.history.pushState({ page: 1 }, "title 1", `?page=${loadNewPageState.currentPage}`);
            return loadNewPageState;
        case LOAD_EXACT_PAGE:
            const exactPageState = Object.assign({}, state);
            const exactPage = action.payload.page;
            let upperCountExact = exactPageState.countPerPage * exactPage;
            let lowerCountExact = upperCountExact - exactPageState.countPerPage;

            let exactHolidays = exactPageState.holidays.slice(lowerCountExact, upperCountExact);
            exactPageState.filteredHolidays = exactHolidays;
            exactPageState.currentCount = upperCountExact;
            exactPageState.currentPage = exactPage;
            window.history.pushState({ page: 1 }, "title 1", `?page=${exactPageState.currentPage}`);

            return exactPageState;

        default:
            return state;

    }
};

export default filterStore;

function sortAsc(arr, field) {
    return arr.sort(function (a, b) {
        if (a[field] > b[field]) return 1;
        if (b[field] > a[field]) return -1;

        return 0;
    })
}

function sortDesc(arr, field) {
    return arr.sort(function (a, b) {
        if (a[field] > b[field]) return -1;
        if (b[field] > a[field]) return 1;

        return 0;
    })
}

function addFilterIfNotExists(filter, appliedFilters) {
    let index = appliedFilters.indexOf(filter);
    if (index === -1) appliedFilters.push(filter);

    return appliedFilters;
}

function removeFilter(filter, appliedFilters) {
    let index = appliedFilters.indexOf(filter);
    if(index >= 0)
        appliedFilters.splice(index, 1);
    return appliedFilters;
}