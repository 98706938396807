import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';


import './faqs.css'


import SocialMedias from '../SocialMedias'
import MailingList from '../MailingList'

import fontawesome from '@fortawesome/fontawesome'
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '@creativebulma/bulma-divider/dist/bulma-divider.min.css'

fontawesome.library.add(faMinus, faPlus);

const FAQ = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(faq.open || false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <React.Fragment>
            <div className='container'>

                {/*<FontAwesomeIcon icon={`${isOpen ? 'minus' : 'plus'}`} />*/}
                <article class="message has-background-primary-light is-primary is-light is-medium pb-2">
                    <div class="message-header" onClick={toggle}>

                        <p>{faq.question}</p>
                        <FontAwesomeIcon icon={`${isOpen ? 'minus' : 'plus'}`} pull="right" />
                    </div>

                    <div class={!isOpen ? 'message-body hidden has-text-justified' : 'message-body has-text-justified'}>
                        {faq.answer}
                    </div>
                </article>
            </div>
        </React.Fragment>
    )
}

class FAQs extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        let faqs = this.props.faqs

        return (
            <div className='main-content pt-5'>
                <div className='container pt-5'>
                    <div className="columns is-multiline">
                        <div class="column is-8">
                            <div class="box">
                                <div class="box-header">
                                    <h2 class="title is-4 mt-0">
                                        Sol Passport FAQs
                                    </h2>
                                    <small>Main questions about Sol Passport</small>
                                </div>
                                <div class="box-content ">

                                    <dl>
                                        {faqs.map((faq, index) => (
                                            <FAQ faq={faq} key={index} />
                                        ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div class="column is-4 is-narrow">

                            <div class="card-wrapper">
                                <div class="card">
                                    <header class="card-header">
                                        <p class="card-header-title">
                                            Subscribe
                                        </p>
                                    </header>
                                    <div class="card-content">
                                        <div class="content">
                                            <p>Subscribe to our Newsletter to get news about Sol Passport and amazing deals!</p>
                                            <form>
                                                <MailingList style="fullsize" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-wrapper">
                                <div class="card">
                                    <header class="card-header">
                                        <p class="card-header-title">
                                            Social Medias
                                        </p>
                                    </header>
                                    <div class="card-content">

                                        <SocialMedias />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



function mapStateToProps(state) {
    return { state };
}


export default connect(mapStateToProps)(FAQs);
