import React from "react";
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";

import { RWebShare } from "react-web-share";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { connect } from 'react-redux';
import fontawesome from '@fortawesome/fontawesome'
import './holiday_card.css'
import '../../styles/ribbon.css'
import '../../styles/font-awesome-4.7.0/css/font-awesome.min.css'

import SocialMedias from '../SocialMedias'

import { faInfoCircle, faCheckSquare, faCoffee, faUser, faStarHalf, faStar, faDollarSign, faPlus, faPlane, faBuilding } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setHotelAsFavorite, changeModalState } from "../../store";
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faInstagram} from "@fortawesome/free-brands-svg-icons";


fontawesome.library.add(fab, faTwitterSquare, faFacebook, faLinkedin, faGithub,faInstagram, faInfoCircle, faCheckSquare, faCoffee, faUser, faStarHalf, faStar, faDollarSign, faPlus, faPlane, faBuilding);

const zeroPad = (num, places) => String(num).padStart(places, '0')
function DisplayRating(props) {
    const rating = props.rating;
    let base_stars = [...Array(parseInt(rating))].map(a => (<FontAwesomeIcon icon="star" size="lg" style={{ color: "#DAA520" }} />))
    if (parseInt(rating) < parseFloat(rating)) {
        base_stars.push(<FontAwesomeIcon icon="star-half" size="lg" style={{ color: "#DAA520" }} />)
    }
    return base_stars
}
function DisplayBudget(props) {
    const budget = props.budget;

    const rating = budget.charAt(0).toLowerCase().charCodeAt(0) - 97 + 1

    let budget_icons = [...Array(parseInt(rating))].map(a => (<FontAwesomeIcon icon="fa-dollar-sign" size="lg" style={{ color: "green" }} />))

    if (budget.length > 1) {
        budget_icons.push(<FontAwesomeIcon icon="fa-plus" style={{ color: "green" }} />)
    }

    return budget_icons
}

class HolidayCard extends React.Component {
    setFavorite(hotel_name) {
        this.props.dispatch(setHotelAsFavorite({ hotel_name }));
    }
    switchModal = (modal_name) => {
        this.props.dispatch(changeModalState({ modal_name }));
    };

    render() {
        const { name, budget_with_flight, booking_url, destination, description, is_favorite, budget, sol_rating, official_rating, travel_time, image_url, facilities } = this.props.holiday;
        const destination_obj = this.props.destination;
        const month_seleted = this.props.month_seleted;
        let ribbon_weather = null;

        if (month_seleted && destination_obj["weather_" + month_seleted]) {
            if (destination_obj["weather_" + month_seleted].toLowerCase() === "green")
                ribbon_weather = <div className="ribbon is-primary is-medium"><span style={{ color: "hsl(48, 100%, 67%)", "padding-right": "3px" }}> <i class="fas fa-sun"></i></span> Guaranteed</div>

            if (destination_obj["weather_" + month_seleted].toLowerCase() === "orange")
                ribbon_weather = <div className="ribbon is-warning is-medium"><span style={{ color: "hsl(0, 0%, 96%)", "padding-right": "3px" }}> <i class="fas fa-cloud"></i></span> No Sun Guaranteed</div>
        }

        let modal_hotel_name = "modal_hol_" + name.replace(/,/g, '').replace(/ /g, '').replace(/-/g, '');
        let modal_destination_name = "modal_dest_" + destination.replace(/,/g, '').replace(/ /g, '').replace(/-/g, '');
        let modal_sub_name = "modal_sub_" + name.replace(/,/g, '').replace(/ /g, '').replace(/-/g, '');

        return (

            <div class="card is-horizontal shadow-md is-cursor-pointer transform is-duration-300 hover-shadow-xl hover-translate-y">
                <div class="card-image has-ribbon-left preloader" onClick={e => {
                    this.setFavorite(name);
                }}>
                    {ribbon_weather}
                    <figure class="image cover-image is-overflow-hidden">
                        <LazyLoadImage src={image_url}
                            alt="Resort Image" />
                        <span class="is-size-6 p-1 px-3 is-absolute is-tr-1 ">
                            <svg class={"w-6 " + (is_favorite ? 'is-fill-red' : 'is-fill-white')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z">
                                </path>
                            </svg>
                        </span>
                    </figure>
                </div>
                <div class="card-content p-0 m-0 is-flex is-flex-direction-column is-shady ">
                    <div class="content p-0 m-0 has-text-grey-light">
                        <span className="is-pulled-right pt-1 pr-3">
                            <RWebShare
                                data={{
                                    text: "Resorts with sun Guaranteed on SolPassport! Check this one out: ",
                                    url: window.location.href + '?hotel_name=' + name.replace(/ /g, "%20").replace(/,/g, "%2C"),
                                    title: "Sol Passport",
                                }}
                                onClick={() => console.log("shared successfully!")}
                            >

                                <span className="fa-layers fa-fw pb-5">
                                    <FontAwesomeIcon icon="circle" color="hsl(217, 71%, 53%)" size="2x" />
                                    <FontAwesomeIcon icon="link" inverse transform="shrink-6" size="2x" />
                                </span>
                            </RWebShare>
                        </span>
                        <div className="is-pulled-right">
                            <FacebookShareButton
                                url={window.location.href + '?hotel_name=' + name.replace(/ /g, "%20").replace(/,/g, "%2C")}
                                quote={'Resorts with sun Guaranteed on Sol Passport! Check this one out: '}
                                hashtag={["#solpassport", "#beachbliss", "#sunguaranteed"]}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                title="Resorts with sun Guaranteed on Sol Passport! Check this one out: "
                                hashtags={["family"]}
                                url={window.location.href + '?hotel_name=' + name.replace(/ /g, "%20").replace(/,/g, "%2C")}
                                hashtag={["#solpassport", "#beachbliss", "#sunguaranteed"]}
                                related={["@solpassport"]}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <PinterestShareButton
                                url={window.location.href + '?hotel_name=' + name.replace(/ /g, "%20").replace(/,/g, "%2C")}
                                description={'Resorts with sun Guaranteed on Sol Passport. Check this one out! '}
                                hashtag={["#solpassport", "#beachbliss", "#sunguaranteed"]}
                                media={image_url}
                            >
                                <PinterestIcon size={32} round />
                            </PinterestShareButton>
                            <EmailShareButton subject="Resorts with sun Guaranteed on Sol Passport"
                                body="Check out what I found on SolPassport! It looks amazing! "
                                url={window.location.href + '?hotel_name=' + name.replace(/ /g, "%20").replace(/,/g, "%2C")}
                            ><EmailIcon size={32} round={true} /></EmailShareButton>

                        </div>
                    </div>
                    <div class="content p-0 m-0 has-text-grey">
                        <h3 className="mt-1">{destination}</h3>
                        <h4 className="mt-1">{name}</h4>
                        <p class="has-text-justified" style={{ "white-space": "pre-wrap" }}>{description.replaceAll(". ", ".\n")}</p>
                        <p class="is-size-6 has-text-weight-normal">
                            <div className='tile is-child box'>

                                <p>
                                    <b>Official rating: </b>
                                    <span class="has-text-warning-dark"><DisplayRating rating={official_rating.replace("*", "")} /> </span>
                                    {official_rating}
                                </p>
                                <p>
                                    <span class="has-tooltip-arrow has-tooltip-right has-tooltipl-multiline" data-tooltip="The budget define how costly the package is&#10;compared to others&#10;More info in the tab 'FAQ'" ><FontAwesomeIcon style={{ "margin-right": "10px" }} icon={faInfoCircle} /></span>
                                    <b>Budget: </b>
                                    <DisplayBudget budget={budget_with_flight} />
                                </p>
                                <p>
                                    <span class="has-tooltip-arrow has-tooltip-right has-tooltipl-multiline" data-tooltip="Rate the family friendliness&#10;of the hotel and the destination&#10;More info in the tab 'FAQ'" ><FontAwesomeIcon style={{ "margin-right": "10px" }} icon={faInfoCircle} /></span>
                                    <b>Our Rating: </b>
                                    <span class="has-text-warning-dark"><DisplayRating rating={sol_rating} /></span>
                                    {sol_rating}
                                </p>
                                <p><span class="has-tooltip-arrow has-tooltip-right has-tooltipl-multiline" data-tooltip="The Travel time is equal to&#10;flying + transfer to hotel&#10;More info in the tab 'FAQ'" ><FontAwesomeIcon style={{ "margin-right": "10px" }} icon={faInfoCircle} /></span>

                                    <b>Travel Time: </b>
                                    {zeroPad(parseInt(new Date(parseInt(travel_time) * 60 * 1000).toISOString().substr(11, 2)), 2) + "h" + zeroPad(parseInt(new Date(parseInt(travel_time) * 60 * 1000).toISOString().substr(14, 2)), 2) + "mn"}

                                    <p class="is-size-7">(Flying time is calculated from London)</p>
                                </p>
                            </div>
                        </p>

                    </div>

                    <div class="content pt-5 pb-0 mb-2 has-background-info-light">
                        <div class="columns mb-0">
                            {/*<div class="column">
                                <div class="is-size-6">
                                    <span class="has-text-weight-semibold">196 km</span> from Istanbul
                                </div>
                                <div class="is-size-6">
                                    <span class="has-text-primary-dark">&#9733;&#9733;&#9733;&#9733;&#9733;</span> 10 Reviews
                                </div>
                            </div>
                            <div class="column">
                                <button onClick={e => {
                                    this.switchModal(modal_sub_name);
                                }} class="button is-link is-medium is-fullwidth has-text-weight-semibold" ><FontAwesomeIcon icon="building" pull="left" />Check Prices For This Holiday<FontAwesomeIcon icon="plane" pull="right" /></button>
                            </div>*/}
                            <div class="column   has-ribbon-right has-ribbon-bottom">
                                <div className="ribbon is-info is-small">With Booking.com</div>
                                <a href="https://www.booking.com/flights/index.en-gb.html?aid=8074306" target="_blank" class="button is-primary is-medium is-fullwidth has-text-weight-semibold"><FontAwesomeIcon icon="plane" pull="left" />
                                    Book The Flight
                                </a>
                            </div>
                            <div class="column has-ribbon-right has-ribbon-bottom">
                                <div className="ribbon is-info is-small">With Booking.com</div>
                                <a class="button is-link is-medium is-fullwidth has-text-weight-semibold" href={booking_url + "?aid=8074306"} target="_blank" ><FontAwesomeIcon icon="building" pull="left" />Book The Hotel</a>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer-item pb-0 pt-0 is-justify-content-left is-uppercase">
                        <p class="has-text-weight-semibold is-size-7">Highlights</p>
                    </div>

                    <div class="card-footer-item">
                        <div class="columns is-mobile">
                            {/* Not more than 4 columns allowed otherwise it breaks on small screens*/}
                            {
                                facilities.map(facility => {
                                    return (<div class="column">
                                        <LazyLoadImage src={"svg/" + facility.icon + ".svg#svgView(viewBox(24,0,24,24)"}
                                            height="48" width="48" alt={facility.icon + " Icon"} />
                                        <br />
                                        {facility.label}
                                    </div>
                                    )
                                })

                            }
                        </div>
                    </div>

                    <div class="columns" style={{ "margin-top": "00px" }}>
                        <div class="column">
                            {/*                        <a onClick={e => {
                            this.switchModal(modal_hotel_name);
                        }} className="button is-small is-link is-light">
                            <FontAwesomeIcon icon="building" pull="left" />More info about the hotel on booking.com</a>
*/}
                            <a href={booking_url + "?aid=8074306"} target="_blank" className="button is-small is-link is-light">
                                <FontAwesomeIcon icon="building" pull="left" />More info about the hotel on booking.com</a>
                        </div>

                        {/*
                        <div class="column">
                            <a onClick={e => {
                                this.switchModal(modal_destination_name);
                            }} className="button is-small is-link is-light">
                                <FontAwesomeIcon icon="plane" pull="left" />More info about the destination
                            </a>
                        </div>
                        */}
                    </div>
                    <footer class="card-footer is-flex-direction-column has-background-white-bis">
                        <div class="card-footer-item pb-0 pt-3 is-justify-content-left is-uppercase">
                            <p class="has-text-weight-semibold is-size-7">Join us on Social Medias!</p>
                        </div>
                        <div class="card-footer-item media">
                            <div class="media-left">
                            </div>
                            <div class="">
                                <span className="p-1"><SocialMedias/></span>                                               
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { state };
}

export default connect(mapStateToProps)(HolidayCard);