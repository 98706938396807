import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";

import fontawesome from '@fortawesome/fontawesome'

import {
  PinterestIcon,
  TwitterIcon,
} from "react-share";

fontawesome.library.add(fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faInstagram);

export default function SocialMedias() {
    return (
        <>
        <div class="columns is-multiline" >
            <div className='column p-1'>
            <a href='https://x.com/solpassport' className="button is-small is-link is-light" target="_blank"><TwitterIcon size={40} class="pr-2" round /> Twitter  </a>
            </div>
            
            <div className='column p-1'>
                <a href="https://www.pinterest.co.uk/solpassport/" className="button is-small is-link is-light" target="_blank"><PinterestIcon size={40} class="pr-2" round /> Pinterest </a>
            </div>
            
            <div className='column p-1'>
            <a href="https://www.instagram.com/solpassport/" className="button is-small is-link is-light" target="_blank">

                <span className="fa-layers fa-fw fa-3x">
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                    <FontAwesomeIcon icon={['fab', 'instagram']} transform="shrink-6" inverse />
                </span>
                Instagram 
            </a>
            </div>
        </div>
      </>)
}